<template>
  <b-card-code class="h-100">
    <b-row class="py-1">
      <b-col md='4'>
        <b-form-input
          v-model="search"
          placeholder="Search by name or amount"
          @input="handleSearch"
        />
      </b-col>
    </b-row>
    <div style="width: 100%;height:100%; overflow: auto">
      <table class="table table-bordered" style="min-width: 28.9cm">
        <thead class="text">
          <tr>
            <th>Sr. No</th>
            <th>User Name</th>
            <th>Earning</th>
            <th>TDS</th>
            <th>Admin Charges</th>
            <th>Net Amount</th>
            <th>%</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, id) in commissionList" :key="id">
            <td>{{ id + 1 }}</td>
            <td>{{item.user?item.user.fullname:''}}</td>
            <td>{{item.earning}}</td>
            <td>{{item.tds_amount}}</td>
            <td>{{item.admin_charges}}</td>
            <td>{{item.net_amount}}</td>
            <td>{{item.per}}</td>
            <td>{{item.status}}</td>
          </tr>
          <tr v-if="commissionList.length == 0">
            <td colspan="9">
              <div class="d-flex justify-content-center w-100">
                <div>
                  No data found
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import axios from '@/components/axios';
import moment from "moment";
import _ from 'lodash';
import { BFormCheckbox,BDropdown,BDropdownItem, BButton,BFormInput,BRow,BCol } from 'bootstrap-vue';

export default {
      components:{BCardCode,BFormCheckbox,BDropdown,BDropdownItem,BButton,BFormInput,BRow,BCol},
      data(){
        return{
            accessToken: localStorage.getItem("accessToken"),
            baseApi: process.env.VUE_APP_APIENDPOINT,
            commissionList:[],
            allChecked:false,
            search:'',
        }
      },
      mounted(){
        this.getCommissionList()
      },
      methods:{
        async getCommissionList(){
          await axios({
                method: "GET",
                url: `${this.baseApi}/getCommissionList/Paid`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
                params:{search:this.search}
              })
                .then((json) => {
                  this.commissionList= json.data.data

                })
                .catch((error) => console.log(error, "error"));
        },
        handleSearch: _.debounce(function () {
          this.getCommissionList()

        }, 1000),
      }
}
</script>
<style>
  .gap-1{
    gap:10px
  }
</style>
